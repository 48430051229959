<script setup lang="ts">
import type { Category } from '@shopware-pwa/types';
import {
  getTranslatedProperty,
  getCategoryRoute,
} from '@shopware-pwa/helpers-next';

defineProps<{ category: Category; isSocialNavigation?: boolean }>();

const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const linkTarget = (navigationChild: Category) =>
  navigationChild.externalLink || navigationChild.linkNewTab ? '_blank' : '';
</script>
<template>
  <NuxtLink
    v-if="category.type !== 'folder'"
    :target="linkTarget(category)"
    :to="
      category.linkType === 'external'
        ? category.externalLink
        : formatLink(getCategoryRoute(category))
    "
    :aria-label="`link zu ${category.name}`"
    class="!text-grey text-base font-normal hover:!text-white hover:underline"
  >
    <NuxtImg
      v-if="isSocialNavigation"
      class="h-8 w-8"
      :alt="`Folge uns auf ${category.name}`"
      :src="`/logo/${category.name?.toLowerCase()}.svg`"
    />
    <template v-else>
      {{ getTranslatedProperty(category, 'name') }}
    </template>
  </NuxtLink>
  <p v-else class="text-grey">{{ getTranslatedProperty(category, 'name') }}</p>
</template>
